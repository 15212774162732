import { Injectable } from '@angular/core';
import { Checklist } from 'src/app/resource/dto/checklist';
import { CoreUtil as util } from 'src/app/core/core-util';
import { HttpClientService } from 'src/app/core/service/http-client.service';
import { AbstractService } from 'src/app/core/abstract/abstract-service';
import { Constants } from 'src/constants';

@Injectable()
export class ChecklistService extends AbstractService<Checklist> {
	constructor(httpClientService: HttpClientService) {
		super(httpClientService);

		this._microService = Constants.constant.microServices.insurance;
		this._endPoint = Constants.constant.endPoints.checklist;
	}

	isValid(certification: Checklist): boolean {
		return util.isNotEmpty(certification.description);
	}

	getRequestData(checklist: Checklist) {
		return {
			description: checklist.description,
			status: checklist.status
		};
	}

	updateState(object: Checklist): void {
		this._trigger.next(object);
	}

	updateHistoryState(object: Checklist): void {
		this._historyTrigger.next(object);
	}
}