import { Injectable } from '@angular/core';
import { AbstractService } from 'src/app/core/abstract/abstract-service';
import { HttpClientService } from 'src/app/core/service/http-client.service';
import { Certification } from 'src/app/resource/dto/certification';
import { CoreUtil as util } from 'src/app/core/core-util';
import { Constants } from 'src/constants';

@Injectable()
export class CertificationService extends AbstractService<Certification> {
	constructor(httpClientService: HttpClientService) {
		super(httpClientService);

		this._microService = Constants.constant.microServices.general_register;
		this._endPoint = Constants.constant.endPoints.certification;
	}

	isValid(certification: Certification): boolean {
		return util.isNotEmpty(certification.description);
	}

	getRequestData(certification: Certification) {
		return {
			id: certification.id,
			description: certification.description,
			status: certification.status,
			vendorId: certification.vendorId,
			validUntil: certification.validUntil,
			associatedQuantity: certification.associatedQuantity,
			confirmChange: certification.confirmChange
		};
	}

	updateState(object: Certification) {
		if (object) { this._trigger.next(object); }
	}

	updateHistoryState(object: Certification) {
		if (object) { this._historyTrigger.next(object); }
	}
}