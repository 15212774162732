import {Injectable} from '@angular/core';
import {HttpClientService} from 'src/app/core/service/http-client.service';
import {AbstractService} from 'src/app/core/abstract/abstract-service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Constants} from 'src/constants';
import {CoreUtil as c} from 'src/app/core/core-util';
import {Response} from '../../../../dto/response';
import {ProductResponseModel, ProductsNamesResponseList} from '../model/product-response.model';
import {map} from 'rxjs/operators';
import {baseProductEnum} from '../model/base-product.enum';
import {ProductBaseService} from '../../product-instance/service/product-base.service';
import {Pagination} from 'src/app/core/dto/pagination';
import {ObjectMultiFilter} from 'src/app/core/dto/object-multi-filter.dto';
import {transformStatusCustomerAndStatusSales} from '../../util/transformStatusCustomerAndStatusSales.util';
import {CustomerType} from '../create-products/model/customer.model';
import {enumTransform} from '../../../../../shared/helpers/pipe-function/enum-transform';
import { CategorySubcategoryModel } from '../model/category-sub-category.model';

@Injectable()
export class ProductsService extends AbstractService<any> {
	public mainDataObservable: Observable<any>;
	private _mainDataTrigger = new BehaviorSubject<any>(undefined);
	private _dirtyTrigger = new BehaviorSubject<boolean>(false);
	private _authorizeMicroService = Constants.constant.microServices.authorizer;

	constructor(
		httpClientService: HttpClientService,
		private productBaseService: ProductBaseService,
	) {
		super(httpClientService);
		this._params = ['name', 'type'];
		this.mainDataObservable = this._mainDataTrigger.asObservable();
		this._microService = Constants.constant.microServices.product;
		this._endPoint = Constants.constant.endPoints.product;
	}

	getProductList(
		searchText: string,
		pagination: Pagination,
		mapFilter: Map<string, ObjectMultiFilter>,
		sortByAttribute: string,
		queryParams: string):
		Observable<Response<ProductResponseModel[]>> {
		return this.getFilteredList(searchText, pagination, mapFilter, sortByAttribute, queryParams)
			.pipe(
				map(this.transformAvailableInSaleCatalogueInString),
				map(transformStatusCustomerAndStatusSales));
	}

	isValid(product: any): boolean {
		return c.isNotEmpty(product.name) && c.isNotEmpty(product.type);
	}

	getRequestData(characteristics: any) {
		return {
			name: characteristics.name,
			type: characteristics.type,

		};
	}

	setDirty(dirty: boolean) {
		this._dirtyTrigger.next(dirty);
	}

	updateState(product: any) {
		this._trigger.next(product);
	}

	updateMainDataState(product: any) {
		this._mainDataTrigger.next(product);
	}

	updateHistoryState(product: any) {
		this._historyTrigger.next(product);
	}

	getProductsNames(strSearch: string, queryParams?: string, sortByAttribute?: string) {
		return this.httpClientService.getList(this._microService, this._endPoint + '/names', strSearch, undefined, this._params, undefined, sortByAttribute, queryParams);
	}

	getByIdWithProductBaseTreatment(id: number): Observable<Response<ProductResponseModel>> {
		return this.getById(id).pipe(
			map((data: Response<ProductResponseModel>) => {
				data.data.productCharacteristics.forEach(characteristic => {
					if (this.isBasePort(characteristic.characteristic.base)) {
						characteristic.characteristic.children = [];
					}
				});
				return data;
			})
		);
	}

	getExecutions(): Observable<Response<ProductsNamesResponseList[]>> {
		return this.httpClientService.get(this._microService, this._endPoint + '/execution', null).pipe(
			map(execution => {
				execution.data.forEach(item => {
					const str = item.description.toLowerCase();
					item.description = str[0].toUpperCase() + str.substring(1);
				});
				return execution;
			})
		);
	}

	private transformAvailableInSaleCatalogueInString(response: Response<ProductResponseModel[]>): Response<ProductResponseModel[]> {
		response.data = response.data.map((product) => {
			if (product.requirement == true) {
				product.requirementMask = 'Yes';
			}
			if (product.requirement == false) {
				product.requirementMask = 'No';
			}

			return product;
		});

		return response;
	}

	private isBasePort(base: string): boolean {
		return base == baseProductEnum.REGION ||
			base == baseProductEnum.PORT ||
			base == baseProductEnum.PORT_TERMINAL ||
			base == baseProductEnum.MOVEMENT_TYPE;
	}

	validateIfProductCanBeDeleted(productId: number): Observable<Response<ProductResponseModel>> {
		return this.httpClientService.getById(this._microService, this._endPoint + '/validate-block', productId);
	}

	blockProduct(productId: number): Observable<Response<ProductResponseModel>> {
		return this.httpClientService.delete(this._microService, this._endPoint + '/block', productId);
	}

	getCustomerTypes(): Observable<Response<CustomerType[]>> {
		return this.httpClientService.get(this._microService, this._endPoint + '/customer-type', null);
	}

	getDepartmentRole(page: number, pageSize: number, strSearch: string): Observable<Response<any[]>> {
		let endpoint = `department-name?page=` + page + `&pageSize=` + pageSize;
		if (strSearch != '' && strSearch != null) {
			endpoint += `&strSearch=` + strSearch;
		}

		return this.httpClientService.get(this._authorizeMicroService, endpoint, null);
	}

	getCategoryAndSubcategory(): Observable<Response<CategorySubcategoryModel[]>> {
		const endpoint = this._endPoint + `/category`;

		return this.httpClientService.get(this._microService, endpoint, null);
	}


}