
export enum characteristicTypeEnum {
	SELECT = "select",
	NUMBER = "number",
	BOOLEAN = "boolean",
	TEXT = "text",
}

export enum characteristicTypeUpperCaseEnum {
	SELECT = "SELECT",
	NUMBER = "NUMBER",
	BOOLEAN = "BOOLEAN",
	TEXT = "TEXT",
}

