import { Injectable } from '@angular/core';
import { Pgr } from 'src/app/resource/dto/pgr.dto';
import { HttpClientService } from 'src/app/core/service/http-client.service';
import { AbstractService } from 'src/app/core/abstract/abstract-service';
import { Observable, BehaviorSubject } from 'rxjs';
import { CoreUtil as util } from 'src/app/core/core-util';
import { Constants } from 'src/constants';

@Injectable()
export class PgrService extends AbstractService<Pgr> {
	public mainDataObservable: Observable<Pgr>;

	private _mainDataTrigger = new BehaviorSubject<Pgr>(undefined);

	constructor(httpClientService: HttpClientService) {
		super(httpClientService);

		this._params = ['pgrIdArray', 'validityFrom', 'validityTo', 'status'];
		this.mainDataObservable = this._mainDataTrigger.asObservable();
		this._microService = Constants.constant.microServices.insurance;
		this._endPoint = Constants.constant.endPoints.pgr;
	}

	isValid(p: Pgr): boolean {
		return util.isNotEmpty(p.name) &&
				(util.isNotEmpty(p.validityFrom) && util.isNotEmpty(p.validityTo)) &&
				p.ownedGroupPgr != undefined &&
				(!p.ownedGroupPgr || (p.companyList != undefined && p.companyList.length > 0));
	}

	getRequestData(pgr: Pgr) {
		return {
			name: pgr.name,
			ownedGroupPgr: pgr.ownedGroupPgr,
			companyIdList: pgr.companyList ? pgr.companyList.map(p => p.id) : undefined,
			validityFrom: pgr.validityFrom,
			validityTo: pgr.validityTo
		};
	}

	updateState(pgr: Pgr) {
		this._trigger.next(pgr);
	}

	updateMainDataState(pgr: Pgr) {
		this._mainDataTrigger.next(pgr);
	}

	updateHistoryState(pgr: Pgr) {
		this._historyTrigger.next(pgr);
	}
}