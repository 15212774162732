import { Labels } from '../../../../../internationalization/labels/labels';
import { ObjectMultiFilter } from 'src/app/core/dto/object-multi-filter.dto';


export const customerAndSalesStatusFactory = (placeHolder: any): ObjectMultiFilter => {
	const objectMultiFilter = new ObjectMultiFilter(null, null, placeHolder);
	objectMultiFilter.arrayData = fillOptions();
	objectMultiFilter.hasSearchFilter = false;
	return objectMultiFilter;
}

const fillOptions = (): any[] => {
	const _labels = Labels.getLabels();
	return [
		{id: 'Available', description: _labels.available, type: 'text'},
		{id: 'Unavailable', description: _labels.unavailable, type: 'text'},
		{id: 'Blocked', description: _labels.blocked, type: 'text'},
		{id: 'Waitlisted', description: _labels.waitlisted, type: 'text'},
	];
}