import { ObjectMultiFilter } from "src/app/core/dto/object-multi-filter.dto";
import {Constants} from '../../../../../../../constants';




export function abstractFilterFactory({ entityId, key, arrayData}, microService ): ObjectMultiFilter {
	let objectMultiFilterId;
	const constants = Constants.constant;

	const endPoint = `filters`;
	objectMultiFilterId = new ObjectMultiFilter(microService, endPoint, key);
	const queryParams = `filterKeys=${entityId}`;
	if (key == 'Direction' || key == 'Voyage Status' || key == 'Vessel Situation') objectMultiFilterId.hasSearchFilter = false;

	objectMultiFilterId.arrayData = arrayData;
	objectMultiFilterId.queryParams = queryParams;
	if (key == 'Vessel Name' || key == 'Port' || key == 'Terminal') {
		objectMultiFilterId.idProperty = 'id';
	} else {
		objectMultiFilterId.idProperty = 'description';
	}
	objectMultiFilterId.displayProperty = 'description';
	objectMultiFilterId.label = key;
	objectMultiFilterId.entityId = entityId;


	return objectMultiFilterId;
}
export function abstractFilterInput(placeholder ): ObjectMultiFilter {
	let objectMultiFilterId;

	objectMultiFilterId = new ObjectMultiFilter('', '', placeholder);

	objectMultiFilterId.arrayData = [];
	objectMultiFilterId.queryParams = '';
	objectMultiFilterId.idProperty = 'description'
	objectMultiFilterId.displayProperty = 'description'
	objectMultiFilterId.type = 'input'


	return objectMultiFilterId;
}