import { DropdownGroupListData, DropdownGroupList } from "../../model/dtos/dropdown-group-list/dropdown-group-list.model";

export const createSearchList = (comparisonProperty: string, valueList: any[], searchList: any[]): any[] => {
	let idValueList = [];
	let filteredSearchList = [];
	if (comparisonProperty) {
		idValueList = valueList ? valueList.map(value => value[comparisonProperty]) : [];
		filteredSearchList = searchList.filter(search => !idValueList.includes(search[comparisonProperty]));
	} else {
		idValueList = valueList ? valueList : [];
		filteredSearchList = searchList.filter(search => !idValueList.includes(search));
	}
	return [...valueList, ...filteredSearchList];
};

export const createDropdownGroupSearchList = (
	comparisonProperty: string,
	valueList: DropdownGroupList,
	searchList: DropdownGroupList
): DropdownGroupList => {
	const selectedValues: DropdownGroupListData[] = [];

	valueList.arrayData.forEach((group) => {
		group.list = group.list.filter((child) => child.checked);
		if (group.list.length) selectedValues.push(group);
	});

	searchList.arrayData.forEach((searchListGroup) => {
		searchListGroup.list = searchListGroup.list.filter(
			(searchListGroupChild) =>
				!selectedValues.some((selectedGroup) =>
					selectedGroup.list.some(
						(selectedChild) =>
							selectedChild[comparisonProperty] ===
								searchListGroupChild[comparisonProperty] &&
							selectedChild.groupParam === searchListGroupChild.groupParam
					)
				)
		);

		const index = selectedValues.findIndex(
			(selectedGroup) =>
				selectedGroup.description === searchListGroup.description
		);

		if (index >= 0 && searchListGroup.list.length) {
			selectedValues[index].list = [...selectedValues[index].list, ...searchListGroup.list];
		}

		if (index < 0) {
			selectedValues.push(searchListGroup);
		}

	});

	valueList.arrayGroupParams = selectedValues.map(
		(selectedGroup) => selectedGroup.description
	);

	valueList.arrayData = selectedValues;

	return valueList;
};