import { Injectable } from '@angular/core';
import { OwnPolicy } from 'src/app/resource/dto/own-policy.dto';
import { AbstractService } from 'src/app/core/abstract/abstract-service';
import { HttpClientService } from 'src/app/core/service/http-client.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { EPolicyCoverage } from 'src/app/resource/enums/e-policy-coverage.enum';
import { Constants } from 'src/constants';

@Injectable()
export class OwnPolicyService extends AbstractService<OwnPolicy> {
	// public observable: Observable<OwnPolicy>;
	// public historyObservable: Observable<OwnPolicy>;
	public brokerObservable: Observable<OwnPolicy>;
	public mainDataObservable: Observable<OwnPolicy>;
	public EPolicyCoverage = EPolicyCoverage;

	private _brokerTrigger = new BehaviorSubject<OwnPolicy>(undefined);
	private _mainDataTrigger = new BehaviorSubject<OwnPolicy>(undefined);
	// private _trigger = new BehaviorSubject<OwnPolicy>(undefined);
	// private _historyTrigger = new BehaviorSubject<OwnPolicy>(undefined);

	constructor(httpClient: HttpClientService) {
		super(httpClient);

		// this.observable = this._trigger.asObservable();
		// this.historyObservable = this._historyTrigger.asObservable();
		this.brokerObservable = this._brokerTrigger.asObservable();
		this.mainDataObservable = this._mainDataTrigger.asObservable();
		this._microService = Constants.constant.microServices.insurance;
		this._endPoint = Constants.constant.endPoints.own_policy;
	}

	updateMainDataState(ownPolicy: OwnPolicy) {
		this._mainDataTrigger.next(ownPolicy);
	}

	updateState(ownPolicy: OwnPolicy) {
		this._trigger.next(ownPolicy);
	}

	updateHistoryState(ownPolicy: OwnPolicy) {
		this._historyTrigger.next(ownPolicy);
	}

	isValid(ownPolicy: OwnPolicy): boolean {
		return ownPolicy.company.id > 0 &&
			ownPolicy.insurer.id > 0 &&
			ownPolicy.validityFrom != '' &&
			ownPolicy.validityFrom != undefined &&
			ownPolicy.validityTo != '' &&
			ownPolicy.validityTo != undefined &&
			ownPolicy.atmUser != '' &&
			ownPolicy.atmPassword != '' &&
			ownPolicy.atmPostalNumber != '' &&
			ownPolicy.pgr.name != '' &&
			ownPolicy.rcfdcNumber != '' &&
			ownPolicy.rctrcNumber != '';
	}

	getRequestData(ownPolicy: OwnPolicy) {
		const requestData = {
			id: ownPolicy.id,
			atmPassword: ownPolicy.atmPassword,
			atmPostalNumber: ownPolicy.atmPostalNumber,
			atmUser: ownPolicy.atmUser,
			commodityNotCoveredList: [],
			companyId: ownPolicy.company.id,
			insurerId: ownPolicy.insurer.id,
			policyCoverageList: [{
				insuranceTypeId: EPolicyCoverage.RCTRC,
				number: ownPolicy.rctrcNumber,
				percentual: ownPolicy.rctrcPercentual,
				quantityLimit: ownPolicy.rctrcPolQuantLimit
			}, {
				insuranceTypeId: EPolicyCoverage.RCFDC,
				number: ownPolicy.rcfdcNumber,
				percentual: ownPolicy.rcfdcPercentual,
				quantityLimit: ownPolicy.rcfdcPolQuantLimit
			}],
			pgrId: ownPolicy.pgr.id,
			validityFrom: ownPolicy.validityFrom,
			validityTo: ownPolicy.validityTo,
		};

		if (ownPolicy.commodityNotCovered) requestData.commodityNotCoveredList = ownPolicy.commodityNotCovered.map(d => d.id);

		return requestData;
	}

	getOwnPolicyPost(ownPolicy: OwnPolicy) {
		const requestData = {
			atmPassword: ownPolicy.atmPassword,
			atmPostalNumber: ownPolicy.atmPostalNumber,
			atmUser: ownPolicy.atmUser,
			commodityNotCoveredList: [],
			companyId: ownPolicy.company.id,
			insurerId: ownPolicy.insurer.id,
			policyCoverageList: [{
				insuranceTypeId: EPolicyCoverage.RCTRC,
				number: ownPolicy.rctrcNumber,
				percentual: ownPolicy.rctrcPercentual,
				quantityLimit: ownPolicy.rctrcPolQuantLimit
			}, {
				insuranceTypeId: EPolicyCoverage.RCFDC,
				number: ownPolicy.rcfdcNumber,
				percentual: ownPolicy.rcfdcPercentual,
				quantityLimit: ownPolicy.rcfdcPolQuantLimit
			}],
			pgrId: ownPolicy.pgr.id,
			validityFrom: ownPolicy.validityFrom,
			validityTo: ownPolicy.validityTo,
		};

		if (ownPolicy.commodityNotCovered) requestData.commodityNotCoveredList = ownPolicy.commodityNotCovered.map(d => d.id);

		return requestData;
	}
}