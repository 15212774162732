
export const showExecutionFieldAbstract = (formValuePort, formValueDoor, formValueExecution, operationTypeIsDoor: boolean, event) => {
	if (event.index == 0) {
		formValuePort.setValue(event.isChecked);
	}
	if (event.index == 1) {
		formValueDoor.setValue(event.isChecked);
	}
	operationTypeIsDoor = formValueDoor.value;
	if (!operationTypeIsDoor) {
		formValueExecution.setValue([]);
	}

	return operationTypeIsDoor;
};

export const changeExecutionAbstract = (formValueExecution, executionOptions, showTooltip: boolean, event) => {
	formValueExecution.setValue(event);
	formValueExecution.value.length == executionOptions.length ? showTooltip = true : showTooltip = false;

	return showTooltip;
};

export const getExecutionValueAsStringAbstract = (formValueExecution) => {
	let executionValue: string[];
	executionValue = formValueExecution.value.map(obj => transformText(obj.description));

	return executionValue.join(', ');
};

export const transformText = (text: string) => {
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};