export const InitArrayListOfArray = (count, list, hasInput?, input?) => {
	if (list.length == 0) {
		for (let i = 0; i < count; i++) {
			hasInput ? list.push(input) : list.push([]);
		}
	} else {
		for (let i = 0; i < count; i++) {
			list[i] = input;
		}
	}
};

/**
 * Compares two arrays for equality.
 *
 * This function checks if two arrays are equal by comparing their lengths and
 * their elements. The elements are compared using JSON.stringify to handle
 * deep equality checks.
 *
 * @param arr1 - The first array to compare.
 * @param arr2 - The second array to compare.
 * @returns A boolean indicating whether the two arrays are equal.
 */
export const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
	if (arr1.length !== arr2.length) {
		return false;
	}
	for (let i = 0; i < arr1.length; i++) {
		if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
			return false;
		}
	}
	return true;
};
