import { Audit } from './audit';
export class Port extends Audit {
	public portNumber: number;
	public port: string;
	public compassCode: string;
	public portFlag: boolean;
	public city: string;
	public state: string;
	public country: string | unknown;
	public docsysCode: string;
	public portCode?: string;
	public maerskCode?: string;
	public status?: unknown;

	constructor(obj?: any) {
		super();

		if (obj) {
			Object.keys(obj).forEach(key => {
				try {
					this[key] = obj[key];
				} catch (e) {
					return;
				}
			});
		}
	}

	getRequest() {
		return {
			portNumber: this.portNumber,
			portName: this.port,
			compassCode: this.compassCode,
			portFlag: this.portFlag,
			city: this.city,
			state: this.state,
			country: this.country,
			docsysCode: this.docsysCode,
			portCode: this.portCode,
			maerskCode: this.maerskCode,
			status: this.status
		};
	}
}