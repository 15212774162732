import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/service/http-client.service';
import { AbstractService } from 'src/app/core/abstract/abstract-service';
import {Observable, BehaviorSubject, of} from 'rxjs';
import { Constants } from 'src/constants';
import { CoreUtil as c } from 'src/app/core/core-util';
import {Characteristic} from "../model/characteristic.model";
import {map} from 'rxjs/operators';
import {statusBulletEnum} from '../../../../../shared/enum/status-bullet.enum';
import { Labels } from 'src/internationalization/labels/labels';
import {Response} from '../../../../dto/response';
import {LinkedCostModel} from '../model/linked-cost.model';
import {transformStatusCustomerAndStatusSales} from '../../util/transformStatusCustomerAndStatusSales.util';
import {LinkedProductModel} from '../model/linked-product.model';

@Injectable()
export class CharacteristicsService extends AbstractService<Characteristic> {

	public mainDataObservable: Observable<Characteristic>;
	private _mainDataTrigger = new BehaviorSubject<Characteristic>(undefined);
	private _dirtyTrigger = new BehaviorSubject<boolean>(false);

	constructor(httpClientService: HttpClientService) {
		super(httpClientService);
		this._params = ['name', 'type'];
		this.mainDataObservable = this._mainDataTrigger.asObservable();
		this._microService = Constants.constant.microServices.product;
		this._endPoint = Constants.constant.endPoints.characteristic;
	}

	isValid(characteristics: Characteristic): boolean {
		return c.isNotEmpty(characteristics.name) && c.isNotEmpty(characteristics.type);
	}

	getRequestData(characteristics: Characteristic) {
		return {
			name: characteristics.name,
			type: characteristics.type,
		};
	}

	setDirty(dirty: boolean) {
		this._dirtyTrigger.next(dirty);
	}

	updateState(characteristics: Characteristic) {
		this._trigger.next(characteristics);
	}

	updateMainDataState(characteristics: Characteristic) {
		this._mainDataTrigger.next(characteristics);
	}

	updateHistoryState(characteristics: Characteristic) {
		this._historyTrigger.next(characteristics);
	}

	getNames(params: string){
		const endPoint = `${this._endPoint}/names/`;
		return this.httpClientService.get(this._microService, endPoint, params);
	}

	getLinkedProducts(params: string, pathVariable: string): Observable<Response<LinkedProductModel[]>>{
		return this.httpClientService.get(this._microService, `/product/linked/${pathVariable}`, params).pipe(
			map(transformStatusCustomerAndStatusSales)
		);
	}

	getLinkedCost(characteristicId: number): Observable<Response<LinkedCostModel[]>> {
		return this.httpClientService.get(this._microService, `/product/characteristic/cost-linked/${characteristicId}`, null).pipe(
			map(this.transformCostStatus)
		);
	}

	checkingCharacteristcBlocked(params: string){
		return this.httpClientService.get(this._microService, `/product/characteristic/check-blocked?name=${params}`, null);
	}

	private transformCostStatus(response: Response<LinkedCostModel[]>): Response<LinkedCostModel[]> {
		const labels = Labels.getLabels();
		response.data = response.data.map(cost => {
			let costStatus;
			if(cost.costStatus != null) {
				costStatus = (cost.costStatus as string).toUpperCase();
			}
			switch (costStatus) {
				case 'ACTIVE' :
					cost.costStatus = {colorId: statusBulletEnum.GREEN, textContent: labels.active};
					break;
				case 'INACTIVE' :
					cost.costStatus = {colorId: statusBulletEnum.GRAY, textContent: labels.inactive};
					break;
				case 'EXPIRED' :
					cost.costStatus = {colorId: statusBulletEnum.RED, textContent: labels.expired};
					break;
				case 'BLOCKED' :
					cost.costStatus = {colorId: statusBulletEnum.BLACK, textContent: labels.blocked};
					break;
				default:
					cost.costStatus = {colorId: statusBulletEnum.GRAY, textContent: 'Null', showTooltip: true};
					break;
			}
			return cost;
		});
		return response;
	}
}

