import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {AbstractService} from 'src/app/core/abstract/abstract-service';
import {ObjectMultiFilter} from 'src/app/core/dto/object-multi-filter.dto';
import {Pagination} from 'src/app/core/dto/pagination';
import {HttpClientService} from 'src/app/core/service/http-client.service';
import {Port} from 'src/app/resource/dto/port.dto';
import {DropdownOptionsModel} from 'src/app/shared/model/dropdown-options.model';
import {Constants} from 'src/constants';
import {Response} from '../../../dto/response';
import {map} from 'rxjs/operators';


@Injectable()
export class PortService extends AbstractService<Port> {
	public observable: Observable<Port>;
	public mainDataObservable: Observable<Port>;

	private _mainDataTrigger = new BehaviorSubject<Port>(undefined);

	constructor(httpClientService: HttpClientService) {
		super(httpClientService);

		this._trigger = new BehaviorSubject<Port>(undefined);
		this._params = ['countryIds', 'portIds', 'portCodeIds', 'status'];
		this.observable = this._trigger.asObservable();
		this.mainDataObservable = this._mainDataTrigger.asObservable();
		this._microService = Constants.constant.microServices.general_register;
		this._endPoint = Constants.constant.endPoints.new_port;
	}

	isValid(port: Port): boolean {
		return true;
	}

	updateHistoryState(object: Port): void {
		// TODO: Waiting historic implementation
	}

	getArrayValue(mapFilter: Map<string, ObjectMultiFilter>) {
		return mapFilter.get('1.countryIds').arrayValue.length ||
			mapFilter.get('2.portIds').arrayValue.length ||
			mapFilter.get('3.portCodeIds').arrayValue.length ||
			mapFilter.get('4.status').arrayValue.length;
	}

	getFilteredPortsList(strSearch: string, pagination: Pagination, mapFilter: Map<string, ObjectMultiFilter>, sortByAttribute?: string, queryParams?: string): Observable<any> {
		const endPoint = !strSearch ? `${this._endPoint}?strSearch=` : this._endPoint;
		if (this.getArrayValue(mapFilter) || strSearch) {
			return this.httpClientService.getList(this._microService, endPoint,
				strSearch, pagination, this._params, mapFilter, sortByAttribute, queryParams)
				.pipe(
					map(response => this.transformResponseData(response))
				);
		} else {
			return this.httpClientService.getList(this._microService, endPoint, strSearch,
				pagination, this._params, mapFilter, sortByAttribute, '&countryIds=')
				.pipe(
					map(response => this.transformResponseData(response))
				);
		}

	}

	private transformResponseData(response) {
		return ({
			...response, data: response.data.map((item) => {
				return ({
					...item,
					status: item.status == 'ACTIVE' ? true : false,
					country: item.country.description,
					port: item.port.toUpperCase(),
					portCode: item.portCode.toUpperCase(),
					maerskCode: item.maerskCode.toUpperCase()
				})
			})
		})
	}

	updateMainDataState(port: Port) {
		this._mainDataTrigger.next(port);
	}

	updateState(port: Port) {
		this._trigger.next(port);
	}

	getRequestData(port: Port) {
		return {};
	}

	getCountry(strSearch = '', pagination = new Pagination): Observable<Response<any[]>> {
		return this.httpClientService.getList(this._microService, 'new-country/list', strSearch, pagination, null, null, 'name', '');
	}

	getPort(id): Observable<Response<any[]>> {
		return this.httpClientService.get(this._microService, 'new-port/' + id, null);
	}

	savePort(port: any, formType: string, id?): Observable<any> {
		if (formType == "create") {
			return this.httpClientService.post(this._microService, 'new-port', port);
		}
		if (formType == "edit") {
			return this.httpClientService.patch(this._microService, 'new-port', id, port);
		}
	}

	getPortWithFilter(idsFilter): Observable<any> {
		return this.httpClientService.get(this._microService, `new-port/port?strSearch=&idsFilter=${idsFilter}`, `&page=0&pageSize=20`)
	}

	getPortCodeWithFilter(countryIdList, idsFilter): Observable<any> {
		return this.httpClientService.get(this._microService,
			`new-port/port-code?countryIds=${countryIdList}&idsFilter=${idsFilter}`, `&page=0&pageSize=20`)
	}

	downloadXls(countryIdList?, portIdList?, portCodeList?, statusList?): Observable<void> {
		let endpoint = "xls?type=Port";
		if (countryIdList && countryIdList.length > 0) endpoint += `&countryIds=${countryIdList}`;
		if (portIdList && portIdList.length > 0) endpoint += `&portIds=${portIdList}`;
		if (portCodeList && portCodeList.length > 0) endpoint += `&portCodes=${portCodeList}`;
		if (statusList && statusList.length > 0) endpoint += `&status=${statusList}`
		return this.httpClientService.get(this._microService, endpoint, null, 'application/zip',
			'application/zip', 'blob');
	}
}