import {Component, Input, OnInit} from '@angular/core';
import {Icons} from "../../../../icons";
import {Messages} from "../../../../internationalization/messages/messages";

@Component({
	selector: 'app-alert-notification',
	templateUrl: './alert-notification.component.html',
	styleUrls: ['./alert-notification.component.scss']
})
export class AlertNotificationComponent implements OnInit {
	@Input() iconType;
	@Input() message;

	icon;
	borderColor;

	_messages = Messages.getMessages();
	_icons = Icons;

	constructor() {
	}

	ngOnInit(): void {
		switch (this.iconType) {
			case "warning":
				this.icon = this._icons.icon.warning;
				this.borderColor = '#FBC71E';
				break;

			case "error":
				this.icon = this._icons.icon.error_small;
				this.borderColor = '#ff4136';
				break;

			case "success":
				this.icon = this._icons.icon.success_small;
				this.borderColor = '#30ab22';
				break;

			default:
				this.borderColor = '#D9D9D9';
		}
	}

}
