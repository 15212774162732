import { Audit } from './audit';
export class CutoffsLarDeadlineList extends Audit {
    public larTime: any;
    public documentationHours: any;
    public documentationCuttoffTimeOption: any;
    public id: any;
    public date: any;
    public documentationDate: any;
		public strDate: any;
    public strDateDocumentation: any;
    public larCuttoffTimeOption: any;
    public larWeekday: any;
    public larCuttoffTimeOptionId: any;
    public documentationCuttoffTimeOptionId: any;
    public documentationWeekday?: any;
    public larDate? : any;
}