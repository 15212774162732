import { Constants } from 'src/constants';
import * as moment from 'moment';

export class DateUtil {
	private static _constants = Constants.constant;

	public static stringToDate(dateString: string, format: string): Date {
		let date: Date = null;

		switch (format) {
			case this._constants.dateFormat.YYYY_MM_DD_hyphen:
			case this._constants.dateFormat.YYYY_MM_DD_hyphen_min:
				date = new Date(dateString + ' 00:00');
				break;
			case this._constants.dateFormat.YYYY_MM_DD_hyphen_HH_mm:
			case this._constants.dateFormat.YYYY_MM_DD_hyphen_HH_mm_min:
				date = new Date(dateString);
				break;
			case this._constants.dateFormat.YYYY_MM_DD_slash:
			case this._constants.dateFormat.YYYY_MM_DD_slash_min:
				date = new Date(dateString.replace('/', '-') + ' 00:00');
				break;
			case this._constants.dateFormat.YYYY_MM_DD_slash_HH_mm:
			case this._constants.dateFormat.YYYY_MM_DD_slash_HH_mm_min:
			case this._constants.dateFormat.YYYY_MM_DD_slash_HH_mm_ss:
			case this._constants.dateFormat.YYYY_MM_DD_slash_HH_mm_ss_min:
				date = new Date(dateString.replace('/', '-'));
				break;
			case this._constants.dateFormat.DD_MM_YYYY_hyphen:
			case this._constants.dateFormat.DD_MM_YYYY_hyphen_min:
				const data = dateString.split(' ')[0].split('-');
				date = new Date(data[2] + '-' + data[1] + '-' + data[0] + ' 00:00');
				break;
			case this._constants.dateFormat.DD_MM_YYYY_hyphen_HH_mm:
			case this._constants.dateFormat.DD_MM_YYYY_hyphen_HH_mm_min:
				const data2 = dateString.split(' ')[0].split('-');
				const hora2 = dateString.split(' ')[1];
				date = new Date(data2[2] + '-' + data2[1] + '-' + data2[0] + ' ' + hora2);
				break;
			case this._constants.dateFormat.DD_MM_YYYY_slash:
			case this._constants.dateFormat.DD_MM_YYYY_slash_min:
				const data3 = dateString.split(' ')[0].split('/');
				date = new Date(data3[2] + '-' + data3[1] + '-' + data3[0] + ' 00:00');
				break;
			case this._constants.dateFormat.DD_MM_YYYY_slash_HH_mm:
			case this._constants.dateFormat.DD_MM_YYYY_slash_HH_mm_min:
			case this._constants.dateFormat.DD_MM_YYYY_slash_HH_mm_ss:
			case this._constants.dateFormat.DD_MM_YYYY_slash_HH_mm_ss_min:
				const data4 = dateString.split(' ')[0].split('/');
				const hora4 = dateString.split(' ')[1];
				date = new Date(data4[2] + '-' + data4[1] + '-' + data4[0] + ' ' + hora4);
				break;
		}

		return date;
	}

	public static dateToString(date: Date, format: string): string {
		let dateString: string = '';
		const year = date.getFullYear();
		const month = addZero(date.getMonth() + 1);
		const day = addZero(date.getDate());
		const hourMinute = date.getHours() + ':' + date.getMinutes();
		const seconds = addZero(date.getSeconds());

		function addZero(number) {
			return number <= 9 ? '0' + number : number;
		}

		switch (format) {
			case this._constants.dateFormat.YYYY_MM_DD_hyphen:
			case this._constants.dateFormat.YYYY_MM_DD_hyphen_min:
				dateString = year + '-' + month + '-' + day;
				break;
			case this._constants.dateFormat.YYYY_MM_DD_hyphen_HH_mm:
			case this._constants.dateFormat.YYYY_MM_DD_hyphen_HH_mm_min:
				dateString = year + '-' + month + '-' + day + ' ' + hourMinute;
				break;
			case this._constants.dateFormat.YYYY_MM_DD_slash:
			case this._constants.dateFormat.YYYY_MM_DD_slash_min:
				dateString = year + '/' + month + '/' + day;
				break;
			case this._constants.dateFormat.YYYY_MM_DD_slash_HH_mm:
			case this._constants.dateFormat.YYYY_MM_DD_slash_HH_mm_min:
				dateString = year + '/' + month + '/' + day + ' ' + hourMinute;
				break;
			case this._constants.dateFormat.YYYY_MM_DD_slash_HH_mm_ss:
			case this._constants.dateFormat.YYYY_MM_DD_slash_HH_mm_ss_min:
				dateString = year + '/' + month + '/' + day + ' ' + hourMinute + ':' + seconds;
				break;
			case this._constants.dateFormat.DD_MM_YYYY_hyphen:
			case this._constants.dateFormat.DD_MM_YYYY_hyphen_min:
				dateString = day + '-' + month + '-' + year;
				break;
			case this._constants.dateFormat.DD_MM_YYYY_hyphen_HH_mm:
			case this._constants.dateFormat.DD_MM_YYYY_hyphen_HH_mm_min:
				dateString = day + '-' + month + '-' + year + ' ' + hourMinute;
				break;
			case this._constants.dateFormat.DD_MM_YYYY_slash:
			case this._constants.dateFormat.DD_MM_YYYY_slash_min:
				dateString = day + '/' + month + '/' + year;
				break;
			case this._constants.dateFormat.DD_MM_YYYY_slash_HH_mm:
			case this._constants.dateFormat.DD_MM_YYYY_slash_HH_mm_min:
				dateString = day + '/' + month + '/' + year + ' ' + hourMinute;
				break;
			case this._constants.dateFormat.DD_MM_YYYY_slash_HH_mm_ss:
			case this._constants.dateFormat.DD_MM_YYYY_slash_HH_mm_ss_min:
				dateString = day + '/' + month + '/' + year + ' ' + hourMinute + ':' + seconds;
				break;
		}

		return dateString;
	}

	public static isNowBetweenPeriod(dateFrom: Date, dateTo: Date): boolean {
		const now = new Date();

		if (dateFrom.getTime() == dateTo.getTime() && dateTo.getHours() == 0 && dateTo.getMinutes() == 0 && dateTo.getSeconds() == 0) {
			dateTo.setHours(23);
			dateTo.setMinutes(59);
			dateTo.setSeconds(59);
		}

		return dateFrom.getTime() <= now.getTime() && now.getTime() <= dateTo.getTime();
	}

	public static putHyphenAndH(date: string, format = 'yyyy-MM-dd HH:mm:ss'): string {
		date = this.convertToString(date, format);
		date = date.replace(' ', ' - ');
		return date.replace(':', ':');
	}


	public static isBeforeOrEqual(date1: Date, date2: Date): boolean {
		return date1.getTime() <= date2.getTime();
	}

	public static isBefore(date1: Date, date2: Date): boolean {
		return date1.getTime() < date2.getTime();
	}

	public static isAfterOrEqual(date1: Date, date2: Date): boolean {
		return date1.getTime() >= date2.getTime();
	}

	public static isAfter(date1: Date, date2: Date): boolean {
		return date1.getTime() > date2.getTime();
	}

	public static isSameDay(date1: Date, date2: Date): boolean {
		return date1.getFullYear() === date2.getFullYear()
			&& date1.getMonth() === date2.getMonth()
			&& date1.getDate() === date2.getDate();
	}

	public static dateExist(date: Date): boolean {
		return !Number.isNaN(date.getTime());
	}

	public static convertToDate(data: any, format = 'yyyy-MM-dd HH:mm:ss'): Date {
		data = this.convertToString(data, format);
		return moment(data).toDate();
	}

	public static convertToString(data: any, format = 'yyyy-MM-dd HH:mm:ss'): string {
		return data = moment(data).format(format);
	}
}