import { Injectable } from "@angular/core";
import {BehaviorSubject, Observable} from 'rxjs';
import {AbstractService} from '../../../../../core/abstract/abstract-service';
import {HttpClientService} from '../../../../../core/service/http-client.service';
import {Constants} from '../../../../../../constants';
@Injectable({
    providedIn: 'root'
})
export class FileService extends AbstractService<any> {
 public mainDataObservable: Observable<any>;
 private _mainDataTrigger = new BehaviorSubject<any>(undefined);
 constructor(
  httpClientService: HttpClientService,
 ) {
  super(httpClientService);
  this.mainDataObservable = this._mainDataTrigger.asObservable();
  this._microService = Constants.constant.microServices.file;
  this._endPoint = '';
 }

 getRequestData(object: any): any {
 }

 isValid(object: any): boolean {
  return false;
 }

 updateHistoryState(object: any): void {
 }

 updateState(object: any): void {
 }

}


