import { Role } from './role';
import { Audit } from './audit';
import { ObjectFilter } from 'src/app/core/dto/object-filter.dto';
import { Profile } from './profile.dto';

export class User extends Audit {
	id: number;
	accessToken: string;
	expiresIn: number;
	extExpiresIn: number;
	firstName: string;
	lastName: string;
	locale: string;
	refreshToken: string;
	tokenType: string;
	profileName: string;
	status: string;
	identification: string;
	profile: Profile;
	username: string;
	password: string;
	roleList: Role[] = [];
	brandRoleList: ObjectFilter[];
	departmentRoleList: ObjectFilter[] = [];
	regionRoleList: ObjectFilter[] = [];
	department: ObjectFilter = new ObjectFilter();
	managementRoleIdList: ObjectFilter[];
	mode: boolean;
	regionString: string;
	brandsCompactString: string;
	idToken?: any;
}
