import { Injectable } from '@angular/core';
import { AbstractService } from 'src/app/core/abstract/abstract-service';
import { HttpClientService } from 'src/app/core/service/http-client.service';
import { Contact } from 'src/app/resource/dto/contact';
import { CoreUtil as c } from 'src/app/core/core-util';
import { Constants } from 'src/constants';

@Injectable()
export class ContactService extends AbstractService<Contact> {
	constructor(httpClientService: HttpClientService) {
		super(httpClientService);

		this._microService = Constants.constant.microServices.vendor;
		this._endPoint = Constants.constant.endPoints.contact;
	}

	isValid(object: Contact): boolean {
		return c.isNotEmpty(object.name) && object.departmentList && object.departmentList.length > 0 &&
			((object.telephone != undefined && object.telephone != '') ||
				(object.mobile != undefined && object.mobile != '')) &&
			(object.vendorId == undefined || (object.purposeList && object.purposeList.length > 0)) &&
			(object.vendorId == undefined || c.isNotEmpty(object.email));
	}

	getRequestData(object: Contact) {
		const requestData = {
			departmentList: object.departmentList.map(d => d.id),
			name: object.name,
			telephone: object.telephone,
			mobile: object.mobile,
			email: object.email,
			customerId: object.customerId,
			corporateGroupId: object.corporateGroupId,
			vendorId: object.vendorId,
			status: object.status,
			source: object.source,
			purposeList: object.purposeList ? object.purposeList.map(d => d.id) : undefined
		};

		return requestData;
	}

	getListByCustomerIdAndCorporateGroupId(customerId, corporateGroupId) {
		let queryParams = `customerIdArray=${customerId}`;

		if (corporateGroupId > 0) {
			queryParams += `&corporateGroupIdArray=${corporateGroupId}`;
		}

		return this.get(queryParams);
	}

	updateState(object: Contact) { }

	updateHistoryState(object: Contact) { }
}