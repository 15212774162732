import { Response } from '../../../dto/response';
import { Labels } from 'src/internationalization/labels/labels';
import { statusBulletEnum } from 'src/app/shared/enum/status-bullet.enum';

export const transformStatusCustomerAndStatusSales = (response: Response<any>): Response<any[]> => {
		const labels = Labels.getLabels();
		const colors = statusBulletEnum;
		const properties = ['statusCustomer', 'statusSales'];
		response.data = response.data.map(object => {
			properties.forEach(property => {
				if(object[property] != null) {
					object[property] = (object[property] as string).toUpperCase();
				}
				switch(object[property]) {
					case 'AVAILABLE':
						object[property] = {colorId: colors.GREEN, textContent: labels.available, showTooltip: true};
						break;
					case 'UNAVAILABLE':
						object[property] = {colorId: colors.RED, textContent: labels.unavailable, showTooltip: true};
						break;
					case 'BLOCKED':
						object[property] = {colorId: colors.BLACK, textContent: labels.blocked, showTooltip: true};
						break;
					case 'AVAILABILITY':
						object[property] = {colorId: colors.HALF_RED_GREEN, textContent: labels.waitlisted_availability, showTooltip: true};
						break;
					case 'UNAVAILABILITY':
						object[property] = {colorId: colors.HALF_GREEN_RED, textContent: labels.waitlisted_unavailability, showTooltip: true};
						break;
					default:
						object[property] = {colorId: colors.GRAY, textContent: 'Null', showTooltip: true};
						break;
				}
			});
			return object;
		});
	return response;
}
