import { ObjectMultiFilter } from "src/app/core/dto/object-multi-filter.dto";

export function mapFilterFactory(
	microservice: string,
	endpoint: string,
	label: string,
	arrayData: Array<any> = [],
	afunilate: boolean = true,
	hasSearchFilter: boolean = true
): ObjectMultiFilter {
	const filter = new ObjectMultiFilter(microservice, endpoint, label);
	filter.arrayData = arrayData;
	filter.afunilate = afunilate;
	filter.hasSearchFilter = hasSearchFilter;
	return filter;
}