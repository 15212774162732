import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertNotificationComponent } from './alert-notification.component';
 
 
@NgModule({
  declarations: [
    AlertNotificationComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AlertNotificationComponent
  ]
})
export class AlertNotificationModule { }