import { Injectable } from '@angular/core';
import { Ddr } from 'src/app/resource/dto/ddr.dto';
import { AbstractService } from 'src/app/core/abstract/abstract-service';
import { HttpClientService } from 'src/app/core/service/http-client.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { EDdrOperation } from 'src/app/resource/enums/e-ddr-operation.enum';
import { EDdrType } from 'src/app/resource/enums/e-ddr-type.enum';
import { EPgrOption } from 'src/app/resource/enums/e-pgr-option.enum';
import { Constants } from 'src/constants';

@Injectable()

export class DdrService extends AbstractService<Ddr> {
	public EDdrOperation = EDdrOperation;
	public EDdrType = EDdrType;
	public EPgrOption = EPgrOption;
	public mainDataObservable: Observable<Ddr>;
	public associateCustomerDdrObservable: Observable<Ddr>;

	private _mainDataTrigger = new BehaviorSubject<Ddr>(undefined);
	private _associateCustomerDdrTrigger = new BehaviorSubject<Ddr>(undefined);

	constructor(httpClientService: HttpClientService) {
		super(httpClientService);

		this._params = ['ddrId', 'companyId', 'insurerId', 'validityFrom', 'validityTo', 'status'];
		this.mainDataObservable = this._mainDataTrigger.asObservable();
		this.associateCustomerDdrObservable = this._associateCustomerDdrTrigger.asObservable();
		this._microService = Constants.constant.microServices.insurance;
		this._endPoint = Constants.constant.endPoints.ddr;
	}

	isValid(ddr: Ddr): boolean {
		return ddr.name != '' &&
			ddr.name != undefined &&
			ddr.company.id > 0 &&
			ddr.insurer.id > 0 &&
			ddr.pgr.id > 0 &&
			ddr.validityFrom != '' &&
			ddr.validityFrom != undefined &&
			ddr.validityTo != '' &&
			ddr.validityTo != undefined &&
			ddr.ddrPgrOption != '' &&
			ddr.ddrPgrOption != undefined &&
			(ddr.ddrType && ddr.ddrType.id != undefined) &&
			(ddr.policyQuantityLimit && ddr.policyQuantityLimit != undefined) &&
			(ddr.ddrOperationList != undefined && ddr.ddrOperationList.length > 0) &&
			this.checkddrType(ddr);
	}

	checkddrType(ddr: Ddr): boolean {
		if (ddr.ddrType.id == EDdrType.PartialStipulatedRCTRC) {
			if (ddr.atmIntegration == undefined) {
				return false;
			}

			if (ddr.atmIntegration != undefined && ddr.atmIntegration) {
				if (ddr.postalNumber == undefined || ddr.postalNumber == '') {
					return false;
				}
			}
		}

		return true;
	}

	getRequestData(ddr: Ddr) {
		const requestData = {
			name: ddr.name,
			companyId: ddr.company.id,
			commodityNotCoveredList: ddr.commodityNotCoveredList.map(c => c.id),
			insurerId: ddr.insurer.id,
			validityFrom: ddr.validityFrom,
			validityTo: ddr.validityTo,
			ddrType: ddr.ddrType.id,
			ddrOperationList: ddr.ddrOperationList.map(o => o.id),
			policyQuantityLimit: ddr.policyQuantityLimit,
			pgrId: ddr.pgr.id,
			ddrPgrOption: ddr.ddrPgrOption,
			atmIntegration: ddr.atmIntegration,
			postalNumber: ddr.postalNumber,
		};

		return requestData;
	}

	updateState(ddr: Ddr) {
		this._trigger.next(ddr);
	}

	updateMainDataState(ddr: Ddr) {
		this._mainDataTrigger.next(ddr);
	}

	updateHistoryState(ddr: Ddr) {
		this._historyTrigger.next(ddr);
	}

	updateAssociateCustomerDdrState(ddr: Ddr) {
		this._associateCustomerDdrTrigger.next(ddr);
	}
}