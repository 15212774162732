import { Injectable } from '@angular/core';
import { AbstractService } from 'src/app/core/abstract/abstract-service';
import { OperationalRequirements } from 'src/app/resource/dto/operational.dto';
import { HttpClientService } from 'src/app/core/service/http-client.service';
import { CoreUtil as util } from 'src/app/core/core-util';
import { Constants } from 'src/constants';

@Injectable()
export class IntermodalOperationalService extends AbstractService<OperationalRequirements> {
	constructor(httpClientService: HttpClientService) {
		super(httpClientService);

		this._microService = Constants.constant.microServices.general_register;
		this._endPoint = Constants.constant.endPoints.operational_requirement;
	}

	isValid(operational: OperationalRequirements): boolean {
		return util.isNotEmpty(operational.name);
	}

	getRequestData(operational: OperationalRequirements) {
		return {
			id: operational.id,
			name: operational.name,
			status: operational.status,
			confirmChange: operational.confirmChange,
			associatedQuantity: operational.associatedQuantity
		};
	}

	updateState(object: OperationalRequirements) {
		if (object) { this._trigger.next(object); }
	}

	updateHistoryState(object: OperationalRequirements) {
		if (object) { this._historyTrigger.next(object); }
	}
}