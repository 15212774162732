import { ObjectFilter } from 'src/app/core/dto/object-filter.dto';
import { Audit } from './audit';
import { TransportType } from './transport-type';

export class Transport extends Audit {
	public description: string;
	public type: string;
	public defaultAllCustomers: boolean = false;
	public defaultAllCustomersString: string;
	public status: string;
	public transportType: TransportType = new TransportType();
	public associatedQuantity: number;
	public confirmChange: boolean = true;
	public transportTypeString: string;

	public setObjectFilterValues(obj: ObjectFilter): void {
		this.id = obj.id;
		this.description = obj.description;

		if (!this.transportType) { this.transportType = new TransportType(); }

		this.transportType = obj.data;
	}
}
