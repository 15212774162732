import { Audit } from './audit';

export class Broker extends Audit {
	name: string;
	telephone: string;
	email: string;
	status: string;
	ownPolicyId: number;
	ddrId: number;
}
